"use client";
import React from "react";
import { Box, Button, Divider, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { VersionTagRibbon } from "modules/common/components/VersionTagRibbon";
import { SignInButton, SignUpButton, useAuth } from "@clerk/nextjs";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";
import { MobileMenu } from "modules/common/components/navigation/MobileMenu";

const drawerWidth = 200;

export const DrawerComponent = () => {
  const theme = useTheme();
  const [open, setOpen] = useRecoilState(sidebarOpenAtom);
  const { isSignedIn } = useAuth();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleCloseDrawer = () => setOpen(false);

  return (
    <Drawer
      component="nav"
      sx={{
        width: mdUp ? drawerWidth : 0,
        flexShrink: { md: 0 },
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          [theme.breakpoints.up("md")]: {
            position: "relative",
          },
          [theme.breakpoints.down("md")]: {
            position: "fixed",
            visibility: open ? "visible" : "hidden",
          },
        },
      }}
      variant={mdUp ? "permanent" : "temporary"}
      anchor={mdUp ? "left" : "right"}
      open={mdUp ? true : open}
      onClose={handleDrawerToggle}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <MobileMenu />
        {mdDown && !isSignedIn && (
          <Box sx={{ mt: "auto", padding: 2 }}>
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                marginTop: 2,
              }}
            >
              <SignInButton mode="modal">
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleCloseDrawer}
                >
                  Sign In
                </Button>
              </SignInButton>
              <SignUpButton mode="modal">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleCloseDrawer}
                >
                  Register
                </Button>
              </SignUpButton>
            </Box>
          </Box>
        )}
        <VersionTagRibbon />
      </Box>
    </Drawer>
  );
};
