"use client";

import React, { RefObject, useEffect, useRef, useState } from "react";
import { Box, Button, Divider, useMediaQuery } from "@mui/material";
import {
  ArrowDropDown,
  Explore,
  Groups2,
  ReceiptLong,
  Store,
  Style,
  SwapHoriz,
} from "@mui/icons-material";
import { useAuth } from "@clerk/nextjs";
import { usePathname } from "next/navigation";
import { activeButton } from "utils/utils";
import Link from "modules/common/components/navigation/Link";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";
import { useSetRecoilState } from "recoil";
import useTheme from "@mui/material/styles/useTheme";
import CardSearchAutocomplete from "modules/common/components/NavCardSearch/CardSearchAutocomplete";
import { DropdownMenu } from "modules/common/components/navigation/DropdownMenu";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { DecksIcon } from "modules/common/components/Icons/DecksIcon";
import { CollectionIcon } from "modules/common/components/Icons/CollectionIcon";
import HomeIcon from "@mui/icons-material/Home";

export const exploreMenuItems = [
  {
    href: "/sets",
    text: "Cards",
    icon: <Style sx={{ transform: "rotate(180deg)" }} />,
  },
  {
    href: "/decks/public",
    text: "Decks",
    icon: <DecksIcon />,
  },
  {
    href: "/trade/lists/public",
    text: "Trade Lists",
    icon: <ReceiptLong />,
  },
  {
    href: "/trade/stores/public",
    text: "Stores",
    icon: <Store />,
  },
];

export const tradeMenuItems = [
  {
    href: "/trade/lists/personal",
    text: "Trade Lists",
    icon: <ReceiptLong />,
  },
  {
    href: "/trade/stores/personal",
    text: "Stores",
    icon: <Store />,
  },
];

export const metagameMenuItems = [
  {
    href: "/metagame/legacy",
    text: "Legacy",
    // icon: <MilitaryTechIcon />,
  },
  {
    href: "/metagame/modern",
    text: "Modern",
    // icon: <MilitaryTechIcon />,
  },
  {
    href: "/metagame/pauper",
    text: "Pauper",
    // icon: <MilitaryTechIcon />,
  },
  {
    href: "/metagame/pioneer",
    text: "Pioneer",
    // icon: <MilitaryTechIcon />,
  },
  {
    href: "/metagame/standard",
    text: "Standard",
    // icon: <MilitaryTechIcon />,
  },
];

export const Menu = () => {
  const setSidebarOpen = useSetRecoilState(sidebarOpenAtom);
  const { isSignedIn } = useAuth();
  const pathName = usePathname();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleRouteClick = () => {
    if (mdDown) {
      setSidebarOpen(false);
    }
  };

  const [exploreMenuOpen, setExploreMenuOpen] = useState(false);
  const exploreMenuAnchorRef = useRef<HTMLButtonElement>(null);
  const [tradeMenuOpen, setTradeMenuOpen] = useState(false);
  const tradeMenuAnchorRef = useRef<HTMLButtonElement>(null);
  const [metagameMenuOpen, setMetagameMenuOpen] = useState(false);
  const metagameMenuAnchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuToggle = (
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (
    event: Event | React.SyntheticEvent,
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
    menuAnchorRef: RefObject<HTMLButtonElement>
  ) => {
    handleRouteClick();
    if (
      menuAnchorRef.current &&
      menuAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setMenuOpen(false);
  };

  const handleListKeyDown = (
    event: React.KeyboardEvent,
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (tradeMenuOpen && tradeMenuAnchorRef.current) {
      tradeMenuAnchorRef.current.focus();
    }
  }, [tradeMenuOpen]);

  useEffect(() => {
    if (exploreMenuOpen && exploreMenuAnchorRef.current) {
      exploreMenuAnchorRef.current.focus();
    }
  }, [exploreMenuOpen]);

  useEffect(() => {
    if (metagameMenuOpen && metagameMenuAnchorRef.current) {
      metagameMenuAnchorRef.current.focus();
    }
  }, [metagameMenuOpen]);

  return (
    <Box
      component="nav"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        color: "primary.main",
        textDecoration: "none",
        width: "100%",
        ml: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          color: "primary.main",
          textDecoration: "none",
        }}
      >
        <Link href="/">
          <Button
            size={"small"}
            onClick={handleRouteClick}
            startIcon={<HomeIcon />}
            variant={activeButton(pathName, "/") ? "contained" : "text"}
          >
            Home
          </Button>
        </Link>
        <Box>
          <Button
            size={"small"}
            ref={exploreMenuAnchorRef}
            onClick={() => handleMenuToggle(setExploreMenuOpen)}
            startIcon={<Explore />}
            endIcon={<ArrowDropDown />}
            variant={
              activeButton(
                pathName,
                exploreMenuItems.map((item) => item.href)
              )
                ? "contained"
                : "text"
            }
          >
            Explore
          </Button>
          <DropdownMenu
            buttonRef={exploreMenuAnchorRef}
            isOpen={exploreMenuOpen}
            toggleMenu={() => handleMenuToggle(setExploreMenuOpen)}
            closeMenu={(event) =>
              handleMenuClose(event, setExploreMenuOpen, exploreMenuAnchorRef)
            }
            menuItems={exploreMenuItems}
            handleListKeyDown={(event) =>
              handleListKeyDown(event, setExploreMenuOpen)
            }
          />
        </Box>
        <Box>
          <Button
            size={"small"}
            ref={metagameMenuAnchorRef}
            onClick={() => handleMenuToggle(setMetagameMenuOpen)}
            startIcon={<MilitaryTechIcon />}
            endIcon={<ArrowDropDown />}
            variant={
              activeButton(
                pathName,
                metagameMenuItems.map((item) => item.href)
              )
                ? "contained"
                : "text"
            }
          >
            Metagame
          </Button>
          <DropdownMenu
            buttonRef={metagameMenuAnchorRef}
            isOpen={metagameMenuOpen}
            toggleMenu={() => handleMenuToggle(setMetagameMenuOpen)}
            closeMenu={(event) =>
              handleMenuClose(event, setMetagameMenuOpen, metagameMenuAnchorRef)
            }
            menuItems={metagameMenuItems}
            handleListKeyDown={(event) =>
              handleListKeyDown(event, setMetagameMenuOpen)
            }
          />
        </Box>

        <Link href="/swap-calculator">
          <Button
            size={"small"}
            onClick={handleRouteClick}
            startIcon={<SwapHoriz />}
            variant={
              activeButton(pathName, "/swap-calculator") ? "contained" : "text"
            }
          >
            Swap
          </Button>
        </Link>

        {isSignedIn && (
          <>
            <Divider orientation="vertical" flexItem />
            <Box>
              <Button
                size={"small"}
                ref={tradeMenuAnchorRef}
                onClick={() => handleMenuToggle(setTradeMenuOpen)}
                startIcon={<Groups2 />}
                endIcon={<ArrowDropDown />}
                variant={
                  activeButton(
                    pathName,
                    tradeMenuItems.map((item) => item.href)
                  )
                    ? "contained"
                    : "text"
                }
              >
                Your Trades
              </Button>
              <DropdownMenu
                buttonRef={tradeMenuAnchorRef}
                isOpen={tradeMenuOpen}
                toggleMenu={() => handleMenuToggle(setTradeMenuOpen)}
                closeMenu={(event) =>
                  handleMenuClose(event, setTradeMenuOpen, tradeMenuAnchorRef)
                }
                menuItems={tradeMenuItems}
                handleListKeyDown={(event) =>
                  handleListKeyDown(event, setTradeMenuOpen)
                }
              />
            </Box>
            <Link href="/decks/personal">
              <Button
                size={"small"}
                onClick={handleRouteClick}
                startIcon={<DecksIcon />}
                variant={
                  activeButton(pathName, "/decks/personal")
                    ? "contained"
                    : "text"
                }
              >
                Your Decks
              </Button>
            </Link>
            <Link href="/collection">
              <Button
                size={"small"}
                onClick={handleRouteClick}
                startIcon={<CollectionIcon />}
                variant={
                  activeButton(pathName, "/collection") ? "contained" : "text"
                }
              >
                Collection
              </Button>
            </Link>
          </>
        )}
      </Box>

      {!mdDown && (
        <Box display={"flex"} gap={1}>
          <CardSearchAutocomplete
            styles={{
              width: "200px",
              backgroundColor: theme.palette.background.paper,
              borderRadius: "4px",
            }}
            size="small"
            onSearchComplete={handleRouteClick}
          />
        </Box>
      )}
    </Box>
  );
};
