"use client";

import React, { useState } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ArrowDropDown,
  ArrowDropUp,
  Explore,
  Groups2,
  SwapHoriz,
} from "@mui/icons-material";
import { useAuth } from "@clerk/nextjs";
import { usePathname } from "next/navigation";
import { activeButton } from "utils/utils";
import Link from "modules/common/components/navigation/Link";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";
import { useSetRecoilState } from "recoil";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { DecksIcon } from "modules/common/components/Icons/DecksIcon";
import { CollectionIcon } from "modules/common/components/Icons/CollectionIcon";
import HomeIcon from "@mui/icons-material/Home";
import { exploreMenuItems, metagameMenuItems, tradeMenuItems } from "./Menu";

type MenuItem = {
  href: string;
  text: string;
  icon?: React.ReactElement;
};

export const MobileMenu = () => {
  const setSidebarOpen = useSetRecoilState(sidebarOpenAtom);
  const { isSignedIn } = useAuth();
  const pathName = usePathname();

  const handleRouteClick = () => {
    setSidebarOpen(false);
  };

  const [exploreMenuOpen, setExploreMenuOpen] = useState(false);
  const [tradeMenuOpen, setTradeMenuOpen] = useState(false);
  const [metagameMenuOpen, setMetagameMenuOpen] = useState(false);

  const handleMenuToggle = (
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const renderSubMenu = (items: MenuItem[], isOpen: boolean) => (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map((item) => (
          <Link href={item.href} key={item.href}>
            <ListItemButton sx={{ pl: 4 }} onClick={handleRouteClick}>
              {item.icon && (
                <ListItemIcon>
                  {React.cloneElement(item.icon, { color: "primary" })}
                </ListItemIcon>
              )}
              <ListItemText primary={item.text} />
            </ListItemButton>
          </Link>
        ))}
      </List>
    </Collapse>
  );

  return (
    <List
      component="nav"
      sx={{
        color: "primary.main",
        textDecoration: "none",
        width: "100%",
      }}
    >
      <Link href="/">
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/")}
        >
          <ListItemIcon>
            <HomeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </Link>
      <ListItemButton
        onClick={() => handleMenuToggle(setExploreMenuOpen)}
        selected={activeButton(
          pathName,
          exploreMenuItems.map((item) => item.href)
        )}
      >
        <ListItemIcon>
          <Explore color="primary" />
        </ListItemIcon>
        <ListItemText primary="Explore" />
        {exploreMenuOpen ? (
          <ArrowDropUp color="primary" />
        ) : (
          <ArrowDropDown color="primary" />
        )}
      </ListItemButton>
      {renderSubMenu(exploreMenuItems, exploreMenuOpen)}
      <ListItemButton
        onClick={() => handleMenuToggle(setMetagameMenuOpen)}
        selected={activeButton(
          pathName,
          metagameMenuItems.map((item) => item.href)
        )}
      >
        <ListItemIcon>
          <MilitaryTechIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Metagame" />
        {metagameMenuOpen ? (
          <ArrowDropUp color="primary" />
        ) : (
          <ArrowDropDown color="primary" />
        )}
      </ListItemButton>
      {renderSubMenu(metagameMenuItems, metagameMenuOpen)}
      <Link href="/swap-calculator">
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/swap-calculator")}
        >
          <ListItemIcon>
            <SwapHoriz color="primary" />
          </ListItemIcon>
          <ListItemText primary="Swap" />
        </ListItemButton>
      </Link>
      {isSignedIn && (
        <>
          <Divider />
          <ListItemButton
            onClick={() => handleMenuToggle(setTradeMenuOpen)}
            selected={activeButton(
              pathName,
              tradeMenuItems.map((item) => item.href)
            )}
          >
            <ListItemIcon>
              <Groups2 color="primary" />
            </ListItemIcon>
            <ListItemText primary="Your Trades" />
            {tradeMenuOpen ? (
              <ArrowDropUp color="primary" />
            ) : (
              <ArrowDropDown color="primary" />
            )}
          </ListItemButton>
          {renderSubMenu(tradeMenuItems, tradeMenuOpen)}
          <Link href="/decks/personal">
            <ListItemButton
              onClick={handleRouteClick}
              selected={activeButton(pathName, "/decks/personal")}
            >
              <ListItemIcon>
                <DecksIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Your Decks" />
            </ListItemButton>
          </Link>
          <Link href="/collection">
            <ListItemButton
              onClick={handleRouteClick}
              selected={activeButton(pathName, "/collection")}
            >
              <ListItemIcon>
                <CollectionIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Collection" />
            </ListItemButton>
          </Link>
        </>
      )}
    </List>
  );
};
